import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import './BranchSelection.css';

const BranchSelection = ({ setBranchName, setApiUrl }) => {
  const [branch, setBranch] = useState('');
  const [branchLabel, setBranchLabel] = useState('');
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();

  const handleBranchChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    setBranch(e.target.value);
    setBranchLabel(selectedOption.getAttribute('data-name'));
    setHasError(false); 
    // Save the API link in local storage
    const apiLink = e.target.value;
    localStorage.setItem('apiUrl', apiLink);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!branch) {
      setHasError(true); // Set error if no branch is selected
      return; // Prevent form submission
    }
    setBranchName(branchLabel); // Set the selected branch name
    setApiUrl(branch);          // Set the selected API URL directly
    navigate('/dashboard');    // Navigate to the dashboard
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card mt-5">
            <div className="card-body">
              <h3 className="card-title mb-4">Select Branch</h3>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <select
                    id="branch"
                    className={`form-select ${hasError ? 'border-danger' : ''}`}
                    value={branch}
                    onChange={handleBranchChange}
                  >
                    <option value="">Select...</option>
                    {/* <option value="http://localhost:56114/api/WebApi" data-name="Local">Local</option> */}
                    <option value="https://main.sciencebaseschool.net/api/WebApi" data-name="Main Branch">Main Branch</option>
                    <option value="https://gt.sciencebaseschool.com/api/WebApi" data-name="GT Road">GT Road</option>
                    <option value="https://pc.sciencebaseschool.net/api/WebApi" data-name="Peoples Colony">Peoples Colony</option>
                  </select>
                  {hasError && <div className="text-danger mt-2">Please select a branch before proceeding.</div>}
                </div>
               <div className='text-center'>
                    <button type="submit" className="btn btn-primary">Submit</button>
               </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BranchSelection;
