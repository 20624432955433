import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BranchSelection from './components/BranchSelection';
import Dashboard from './components/Dashboard';
import NavBar from './components/NavBar'; 
import StudentList from './components/StudentList';
import ClassList from './components/ClassList'
import './App.css';

function App() {
  const [branchName, setBranchName] = useState('');
  const [apiUrl, setApiUrl] = useState('');

  return (
    <Router>
      <NavBar /> {}
      <Routes>
        <Route path="/branchselection" element={<BranchSelection setBranchName={setBranchName} setApiUrl={setApiUrl} />} />
        <Route path="/dashboard" element={<Dashboard branchName={branchName} apiUrl={apiUrl} />} />
        <Route path="/" element={<BranchSelection setBranchName={setBranchName} setApiUrl={setApiUrl} />} />
        <Route path="/classlist" element={<ClassList />} />
        <Route path="/studentlist" element={<StudentList />} />

      </Routes>
    </Router>
  );
}

export default App;
